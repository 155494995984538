<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.005"
    height="23.998"
    viewBox="0 0 28.005 23.998"
    :class="color"
  >
    <path
      class="a"
      d="M3320.581,264.288v7"
      transform="translate(-3306.611 -257.288)"
    />
    <g class="a" transform="translate(9.97)">
      <circle class="b" cx="4" cy="4" r="4" />
      <circle class="c" cx="4" cy="4" r="3" />
    </g>
    <path
      class="a"
      d="M3320.581,264.288v7"
      transform="translate(1439.473 3024.886) rotate(-120)"
    />
    <g class="a" transform="translate(4 23.998) rotate(-120)">
      <circle class="b" cx="4" cy="4" r="4" />
      <circle class="c" cx="4" cy="4" r="3" />
    </g>
    <path
      class="a"
      d="M3320.581,264.288v7"
      transform="translate(1909.112 -2726.53) rotate(120)"
    />
    <g class="a" transform="translate(28.005 17.07) rotate(120)">
      <circle class="b" cx="4" cy="4" r="4" />
      <circle class="c" cx="4" cy="4" r="3" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Divisions",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.strom {
  transition: all ease 0.3s;
  .a {
    stroke: $strom;
    stroke-width: 2px;
  }
}
.a,
.c {
  fill: none;
}
.a {
  transition: all ease 0.3s;
  stroke: $midnight;
  stroke-width: 2px;
}
.b {
  stroke: none;
}
</style>
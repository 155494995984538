<template>
  <div class="mobile-event-menu">
    <div class="header">
      <h3>{{ tour.name }}</h3>

      <div class="status-row">
        <div class="status" v-if="tour.isPublished"><On />Published</div>
        <div class="status" v-else><Off />Unpublished</div>
      </div>
      <div class="public-link d-xl-none">
        <span @click="copyLink()"><CopyLink /></span>
        <p>
          <router-link :to="{ name: 'tournamentoverview-standings' }"
            >Public page</router-link
          >
        </p>
      </div>
    </div>
    <div class="desktop-right-column">
      <div class="rounds-pools">
        <span> <EventIcon /></span>
        <p>{{ tour.events.length }} Events</p>
        <div class="tour-type" v-if="tour.type">{{ tour.type.name }}</div>
      </div>
      <div class="event-date" v-if="nextEventDate != null">
        <span><CalendarIcon /></span>
        <p>Next event: {{ nextEventDate }}</p>
      </div>
      <div class="public-link">
        <span @click="copyLink()"><CopyLink /></span>

        <p>
          <router-link :to="{ name: 'tournamentoverview-standings' }"
            >Public page</router-link
          >
        </p>
      </div>
    </div>
    <nav>
      <div class="nav-spacer"></div>
      <router-link :to="{ name: 'tour-overview' }" class="menu-item">
        <div
          class="item-container"
          :class="{ active: $route.name == 'tour-overview' }"
        >
          <div class="icon">
            <EventIcon
              :color="{ strom: $route.name !== 'tour-overview' }"
              class="events-icon"
            />
          </div>
          <div class="text">
            <p>Events</p>
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'tour-overview-divisions' }" class="menu-item">
        <div
          class="item-container"
          :class="{ active: $route.name == 'tour-overview-divisions' }"
        >
          <div class="icon">
            <Divisions
              class="divisions-icon"
              :color="{
                midnight: $route.name == 'tour-overview-divisions',
                strom: $route.name !== 'tour-overview-divisions',
              }"
            />
          </div>
          <div class="text">
            <p>Divisions</p>
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'tour-overview-settings' }" class="menu-item">
        <div
          class="item-container"
          :class="{ active: $route.name == 'tour-overview-settings' }"
        >
          <div class="icon">
            <Settings
              class="settings-icon"
              :color="{ strom: $route.name !== 'tour-overview-settings' }"
            />
          </div>
          <div class="text">
            <p>Settings</p>
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'tour-overview-utility' }" class="menu-item">
        <div
          class="item-container"
          :class="{ active: $route.name == 'tour-overview-utility' }"
        >
          <div class="icon">
            <Settings
              class="settings-icon"
              :color="{ strom: $route.name !== 'tour-overview-utility' }"
            />
          </div>
          <div class="text">
            <p>Utility</p>
          </div>
        </div>
      </router-link>

      <div class="nav-spacer"></div>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import EventIcon from "@/assets/icons/EventIcon";
import CopyLink from "@/assets/icons/CopyLink";
import Settings from "@/assets/icons/Settings";
import Divisions from "@/assets/icons/Divisions";

export default {
  name: "ManagerTourMenu",
  props: ["tour"],
  data() {
    return {};
  },
  components: {
    On,
    Off,
    CalendarIcon,
    EventIcon,
    Settings,
    Divisions,
    CopyLink,
  },
  computed: {
    nextEventDate() {
      if (this.tour.nextEvent) {
        return moment(this.tour.nextEvent.startDate).format("ddd D MMM");
      }
      return null;
    },
    showRegOpeningDate() {
      if (this.event.isRegistrationOpen) return false;
      if (moment(this.event.registrationOpenAt).isAfter()) return true;
      return false;
    },
    waitingListLength() {
      return this.event.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
  },
  methods: {
    copyLink() {
      let envURL = "";

      if (process.env.NODE_ENV === "production") {
        envURL = "https://www.tjing.se/tour";
      } else if (process.env.NODE_ENV === "test") {
        envURL = "https://test.tjing.se/tour";
      } else {
        envURL = "https://dev.tjing.se/tour";
      }
      navigator.clipboard.writeText(`${envURL}/${this.tour.id}`);

      this.$store.dispatch("showMessage", {
        show: true,
        message: "URL copied",
        type: "success",
      });
    },
    regOpenDateFormater(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.right {
  display: none;
}
.desktop-right-column {
  display: none;
}
.status-row {
  margin-bottom: 32px;
  .status {
    display: flex;
    align-items: center;
    font-size: 16px;
    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
}
.mobile-event-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  h3 {
    font-size: 20px;
    margin-bottom: 16px;
    width: 100%;
  }
}
nav {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  min-width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 0px 12px 0px;
  margin-bottom: 24px;
  scroll-snap-type: x proximity;
  scroll-padding: 0 12px;

  ::-webkit-scrollbar {
    display: none;
  }

  .nav-spacer {
    min-width: 12px;
    display: block;
    height: 100%;
    scroll-snap-align: start;
  }
}
.menu-item {
  width: 18%;
  min-width: 50px;
  max-width: 100px;
  position: relative;
  display: flex;
  margin: 2px 4% 2px 0;
  scroll-snap-align: start;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:last-of-type {
    margin-right: 0px;
  }
}
.item-container {
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 0 1px $sleet;
  transition: all ease 0.3s;
  padding: 15% 0px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px $sleet;
  }

  .text {
    p {
      font-size: 3vw;
    }
  }
  .icon {
    svg {
      height: 5.5vw;
      width: auto;
      aspect-ratio: 1;
    }
  }

  &.active {
    box-shadow: 0 0 0 2px $ocean;
    p {
      color: $midnight;
    }
  }

  p {
    transition: all ease 0.3s;
    margin: 0;
    font-size: 16px;
    @include Gilroy-Bold;
    color: $strom;
  }
}
.public-link {
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  svg {
    margin-right: 8px;
    width: 15px;
    height: 20px;
  }

  a:hover {
    text-decoration: underline;
  }
  p {
    font-size: 16px;
    margin: 0;
    color: $ocean;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
  .mobile-event-menu {
    width: 100%;
  }

  nav {
    width: 100%;
    justify-content: flex-start;
    width: 100%;
    a {
      margin-right: 48px;
    }

    .right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@media (min-width: 544px) {
  nav {
    justify-content: flex-start;
  }
  .menu-item {
    max-width: 100px;
    min-width: 100px;
    margin-right: 24px;

    .item-container {
      .icon {
        svg {
          height: 32px;
        }
      }
      .text {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  nav {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .public-link {
    cursor: pointer;
    margin-left: 0;
    svg {
      width: unset;
      height: unset;
      margin-right: 0;
    }
    span {
      display: flex;
      justify-content: center;
      width: 30px;
      margin-right: 8px;
    }

    a:hover {
      text-decoration: underline;
    }
    p {
      margin: 0;
      color: $ocean;
    }
  }
  .mobile-event-menu {
    justify-content: flex-start;
  }
  .desktop-right-column {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .tags {
      display: flex;
      div {
        margin-right: 12px;
      }
    }

    .event-date {
      display: flex;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-right: 8px;
      }
    }
    .rounds-pools {
      margin-top: 0;
      display: flex;
      align-items: center;
      span {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-right: 8px;
      }
    }

    > div {
      display: flex;
      margin-top: 20px;
      button {
        margin-left: 16px;
      }
      p {
        margin: 0;
        font-size: 18px;
      }
    }

    .tour-type {
      color: $sunset;
      margin-left: 16px;
      background: #fff0ec;
      border: 1px solid #ffc5b5;
      display: inline-flex;
      padding: 5px 10px;
      border-radius: 6px;
      @include Gilroy-Bold;
      font-size: 14px;
    }
  }

  .right {
    display: inline-flex;
    .item-container {
      height: 100%;
      position: relative;
    }
    .text {
      white-space: nowrap;
    }
    .icon {
      svg {
        height: unset;
      }
    }
  }
  nav {
    padding: 0;
    margin-top: 48px;
    box-shadow: inset 0px 11px 1px -10px $sleet,
      inset 0px -11px 1px -10px $sleet;
    margin-bottom: 64px;
    overflow: unset;
    max-width: 100%;
    justify-content: flex-start;

    .menu-item {
      min-width: unset;
      max-width: unset;
      scroll-snap-align: start;
      width: auto;
      margin: 0 24px 0 0;

      &::after {
        display: none;
      }

      .item-container {
        position: relative;
      }
    }

    .nav-spacer {
      display: none;
    }
  }
  .item-container {
    background: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 24px 12px;
    height: auto;
    border-bottom: 4px solid transparent;
    transition: all ease 0.3s;

    &:hover {
      box-shadow: none;
      border-bottom: 4px solid $strom;
    }

    &.active {
      box-shadow: none;
      border-bottom: 4px solid $midnight;
    }

    .text {
      p {
        font-size: 18px;
      }
    }
  }
  .header {
    width: 75%;
    border-right: 1px solid $sleet;
    padding: 30px 0 0 0;
    h3 {
      font-size: 32px;
      margin: 0;
    }

    .status-row {
      margin-top: 32px;
      margin-bottom: 0;
      display: flex;

      .status {
        margin-right: 30px;
        display: flex;
        align-items: center;
        font-size: 18px;

        svg {
          height: 24px;
          width: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  .item-container {
    background: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 24px 12px;
    height: auto;
    border-bottom: 4px solid transparent;
    transition: all ease 0.3s;

    &:hover {
      box-shadow: none;
      border-bottom: 4px solid $strom;
    }

    &.active {
      box-shadow: none;
      border-bottom: 4px solid $midnight;
    }
    .icon {
      margin-right: 12px;

      .events-icon {
        width: 26px;
        height: 24px;
      }
      .divisions-icon {
        width: 28px;
        height: 24px;
      }
      .settings-icon {
        width: 24px;
        height: 24px;
      }
    }
    .text {
      p {
        font-size: 18px;
      }
    }
  }
}
</style>

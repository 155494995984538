<template>
  <main v-if="loaded">
    <div class="breadcrumbs">
      <div>
        <p class="tour-name">
          <router-link :to="{ name: 'manage' }">Dashboard</router-link>
        </p>
        <p class="divider">/</p>
        <template v-if="$route.name == 'tour-overview'">
          <p class="tail">Tour events</p>
        </template>
        <template v-if="$route.name == 'tour-overview-divisions'">
          <p class="tail">Tour divisions</p>
        </template>
        <template v-if="$route.name == 'tour-overview-settings'">
          <p class="tail">Tour settings</p>
        </template>
        <template v-if="$route.name == 'tour-overview-add-event'">
          <p class="tail">Add event</p>
        </template>
      </div>
    </div>

    <ManagerTourMenu :tour="tourData" />

    <router-view />
  </main>
</template>

<script>
import ManagerTourMenu from "@/components/ManagerTourMenu";

export default {
  name: "OrganizerTourOverview",
  components: {
    ManagerTourMenu,
  },
  data() {
    return {
      loaded: false,
      tourData: null,
      view: null,
      finishedExpanded: false,
    };
  },
  watch: {},
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    finishedEvents() {
      return this.tourData.events.filter(
        (event) => event.status == "COMPLETED"
      );
    },
    upcomingEvents() {
      return this.tourData.events.filter(
        (event) => event.status != "COMPLETED"
      );
    },
  },
  methods: {
    toggleAddNewEvent() {
      this.view == "addevent" ? (this.view = null) : (this.view = "addevent");
    },
    toggleSettings() {
      this.view == "settings" ? (this.view = null) : (this.view = "settings");
    },
    async loadData() {
      try {
        let tourData = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            {
              tour(tourId:"${this.$route.params.tourId}"){
                id
                name
                type{
                  name
                  type
                }
                isPublished
                isArchived
                status
                eventsCount
                nextEvent{
                  startDate
                }
                events{
                  id
                  name
                  isRegistrationOpen
                  registrationOpenAt
                  status
                  startDate
                  pdgaType{
                    name
                    type
                  }
                  registrations{
                    id
                    status
                  }
                  rounds{
                    id
                    pools{
                      id
                    }
                  }
                }
              }
            }
          `,
          },
        });

        this.tourData = tourData.data.data.tour;
        this.loaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
hr {
  width: 100%;
  border-top: 1px solid $sleet;
  margin: 24px 0;
}
.desktop-right-column {
  display: none;
}
.tour-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 6px;

  h3 {
    font-size: 20px;
    max-width: calc(100% - 40px);
    margin-bottom: 0;
  }

  .sub-header {
    width: 100%;
    display: flex;
    margin-top: 14px;
    padding-bottom: 12px;
    p {
      margin: 0;
      font-size: 14px;
    }

    .status {
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-size: 14px;
      svg {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }
    .public-link {
      display: flex;
      align-items: center;
      a {
        color: $ocean;
        cursor: pointer;
      }
      svg {
        width: 12px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }

  .settings {
    padding-left: 7px;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  h4 {
    padding-right: 10px;
  }
}
.event-list {
  &.finished {
    margin-top: 32px;
  }
  .event-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
  }
}
h4 {
  font-size: 18px;
  @include Gilroy-Bold;
  margin: 0;
}
h6 {
  @include Gilroy-Bold;
  font-size: 16px;
  margin: 0;

  &.light {
    @include Gilroy-Regular;
  }
}

.event-card {
  margin-top: 24px;
}
.breadcrumbs {
  margin: 24px 0 24px 0;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 12px;

  div {
    display: flex;
    width: calc(100% - 20px);
    a {
      color: $ocean;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;

      &.divider {
        min-width: 12px;
        text-align: center;
      }

      &.tail {
        min-width: 46px;
      }
    }
  }
}

@media (orientation: landscape) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  hr {
    width: 100%;
    border-top: 1px solid $sleet;
    margin: 48px 0;
  }
  main {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .tour-header {
    width: 70%;
    border-right: 1px solid $sleet;
    padding-right: 0;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
    h3 {
      font-size: 32px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 625px;
    }

    a {
      margin-left: auto;

      &.router-link-exact-active {
        .settings {
          background: white;
        }
      }
    }
    .settings {
      margin-left: auto;
      margin-right: 32px;
      display: flex;
      align-items: center;
      border: 1px solid $sleet;
      border-radius: 6px;
      padding: 6px 12px;
      transition: all ease 0.3s;

      &:hover {
        background: white;
        cursor: pointer;
      }

      p {
        margin: 0;
        @include Gilroy-Bold;
      }
      svg {
        margin-right: 10px;
        height: 24px;
        width: 24px;
      }
    }
    .sub-header {
      border-bottom: 0;
      margin-top: 32px;
      padding-bottom: 32px;

      .status {
        font-size: 18px;
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
  .desktop-right-column {
    display: flex;

    padding-left: 32px;
    flex-direction: column;
    align-items: flex-start;

    .events-row {
      margin-top: 0;
      display: flex;
      align-items: center;
      span {
        display: flex;
        width: 30px;
        justify-content: center;
        margin-right: 8px;
      }
    }
    .next-event-row {
      display: flex;
      align-items: center;
      span {
        display: flex;
        width: 30px;
        justify-content: center;
        margin-right: 8px;
      }
    }

    > div {
      display: flex;
      margin-top: 20px;
      button {
        margin-left: 16px;
      }
      p {
        margin: 0;
        font-size: 18px;
      }
    }

    .tour-type {
      color: $sunset;
      background: #fff0ec;
      border: 1px solid #ffc5b5;
      display: inline-flex;
      padding: 5px 10px;
      border-radius: 6px;
      @include Gilroy-Bold;
      font-size: 14px;
    }
  }

  .event-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
    h6 {
      width: 100%;
      font-size: 18px;
    }

    .event-card {
      width: 30%;
      margin-top: 32px;
    }

    .event-list-header {
      width: 100%;
    }

    .slide-wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .breadcrumbs {
    padding-bottom: 40px;
    border-right: 1px solid $sleet;
    width: 75%;
    margin: 0;
    display: flex;
    align-items: center;
    div {
      a {
        color: $ocean;

        &.router-link-exact-active {
          color: $midnight;
          text-decoration: none;
        }
      }
      p {
        font-size: 16px;
      }
    }
  }
}
</style>

